import React from 'react';

import styles from '@shopify/polaris/components/Badge/components/Pip/Pip.scss';
import { Progress, Tone, Badge, Size } from '@shopify/polaris/components/Badge';
import { useI18n } from '@shopify/polaris/utilities/i18n';
import { classNames, variationName } from '@shopify/polaris/utilities/css';
import { getDefaultAccessibilityLabel } from '@shopify/polaris/components/Badge/utils';
import { Text } from '@shopify/polaris';

export interface PipProps {
  /** wrapper element tagname, defaults to span */
  as?: keyof HTMLElementTagNameMap;
  progress?: Progress;
  tone?: Tone;
  accessibilityLabelOverride?: string;
}

export { Progress, Tone };
/** This basically gives the status dot from the Badge */
export function Pip({
  as: Wrapper = 'span',
  tone,
  progress = 'complete',
  accessibilityLabelOverride,
}: PipProps) {
  const i18n = useI18n();
  const className = classNames(
    styles.Pip,
    tone && styles[variationName('tone', tone) as keyof typeof styles],
    progress && styles[variationName('progress', progress) as keyof typeof styles],
  );

  const accessibilityLabel = accessibilityLabelOverride
    ? accessibilityLabelOverride
    : getDefaultAccessibilityLabel(i18n, progress, tone);

  return (
    <Wrapper className={className}>
      <Text as="span" visuallyHidden>
        {accessibilityLabel}
      </Text>
    </Wrapper>
  );
}


export function StatusBadge({
  value, trueTone, falseTone, trueLabel, falseLabel, size = "large"
}: {
  value: boolean;
  trueTone: Tone;
  trueLabel: string;
  falseTone: Tone;
  falseLabel: string;
  size?: Size;
}) {
  const label = value ? trueLabel : falseLabel;
  const tone = value ? trueTone : falseTone;
  const progress = value ? "complete" : "incomplete";
  return <Badge size={size} progress={progress} tone={tone}>{label}</Badge>;
}