import { dateCubes, DateExt, parseCubes } from "./date-funcs";
import * as PrismaExtra from "prisma-client";
import { CustomerPaymentInfoFlags, SPPI, StringPathProxy } from "./schema-builder/cubes-utils";
import { ServerPayments } from "./server/Application/ServerPayments";
import { CustomerLedger, SelectTypeTree } from ".";
import { PrismaQuery } from "./PrismaQuery";
import { truthy } from "./schema-builder/graphql-declarator";
import { DateTime } from "luxon";
// import { PrismaConnection } from "./server/PrismaAuthToken";

export type PaymentLedgerKeys = "Customer" | "Branch" | "Owner" | "Division" | "SalesTax";

export function getPreviousRentalStatus(current: PrismaExtra.RentalStatus, isRentToOwn: boolean): PrismaExtra.RentalStatus {
  switch (current) {
    case 'Scheduled': return "Reserved";
    case 'Rented': return "Scheduled";
    case "RentToOwn": return "Scheduled";
    case 'Moving_Out': return isRentToOwn ? "RentToOwn" : "Rented";
    case 'Completed': return "Moving_Out";
    case 'Retained': return "Completed";
    case 'Released': return "Completed";
    case "Reserved":
    case "Archived":
    case "SoldToCustomer":
      throw `Cannot reverse ${current} rental status.`;
    default: {
      const t: never = current;
      throw `Invalid rental status ${current}`;
    }
  }
}

export function getNextRentalStatus(current: PrismaExtra.RentalStatus): PrismaExtra.RentalStatus[] {
  switch (current) {
    case 'Reserved': return ["Scheduled"]
    case 'Scheduled': return ["Rented", "RentToOwn"];
    case 'Rented': return ["Moving_Out"];
    case 'RentToOwn': return ["Moving_Out", "SoldToCustomer"];
    case 'Moving_Out': return ["Completed"];
    case 'Completed': return ["Retained", "Released"];
    case 'Retained': return ["Released"]
    case 'Released': return [];
    case "SoldToCustomer": return [];
    case "Archived": return [];
    default: {
      const t: never = current;
      throw `Invalid rental status ${current}`;
    }
  }
}


interface CognitoJwtFields {
  token_use: "access" | "id";
  "cognito:groups"?: string[];
  sub: string;
  iss: string;
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  origin_jti: string;
}
interface CognitoIdTokenFields extends CognitoJwtFields {
  token_use: "id";
  aud: string;
  at_hash: string;
  "cognito:username": string;
  email_verified: boolean;
  phone_number_verified: boolean;
  identities: {
    userId: string;
    providerName: string;
    providerType: string;
    issuer: null;
    primary: string;
    dateCreated: string;
  }[];
  "cognito:roles": string[];
  "cognito:preferred_role": string;
}

export interface IdTokenPayload extends CognitoIdTokenFields {
  name: string;
  email: string;
}




export const CognitoGroups = ["cubes_central_admin", "cubes_branch_admin"] as const;
export type CognitoGroups = typeof CognitoGroups[number];

// export { PrismaConnection };

export type PrismaClientType = PrismaExtra.PrismaClient;
export type PrismaTypeMap = PrismaExtra.Prisma.TypeMap;
export type PrismaTxnClient = Omit<PrismaClientType, "$on" | "$connect" | "$disconnect" | "$use" | "$transaction" | "$extends">;


export function getNextInvoiceDay(date: Date, payday: number) {
  if (DateExt(date).getDate() >= payday) {
    return DateExt(date).addMonths(1).setDate(payday);
  } else {
    return DateExt(date).setDate(payday);
  }
}
export async function getCustomerActualBalance(client: PrismaTxnClient, customerID: string | undefined) {
  return await client.customerLedger.aggregate({
    _sum: { Amount: true },
    where: {
      customerID, 
      line: {
        VoidSince: null,
        OR: [
          { paymentLine: { PaymentStatus: { in: ["Approved", "Cleared"] } } },
          { invoiceLine: { is: {} } },
        ]
      }
    },
  });
}


export async function getCustomerActualTransactions(client: PrismaTxnClient, customerID: string | undefined) {
  return await client.customerLedger.findMany({
    where: { customerID, line: { VoidSince: null } },
    select: PrismaQuery.selectPathsProxy("CustomerLedger", getCustomerActualTransactions.SelectPaths)
  });
}

getCustomerActualTransactions.SelectPaths = (x: SelectTypeTree<"CustomerLedger">) => {
  return [
    { key: x.id.__, hidden: true, },
    x.line.Date.__,
    x.line.invoiceLine.paidOn.__,
    x.line.invoiceLine.item.ItemName.__,
    // x.line.invoiceLine.item.ItemType.__,
    x.line.invoiceLine.rental.unit.Name.__,
    x.line.invoiceLine.rental.unit.unitType.Name.__,
    x.line.paymentLine.PaymentStatus.__,
    // x.line.paymentLine.PaymentFee.__,
    x.Amount.__,
  ] as const;
};

getCustomerActualTransactions.StringPaths = StringPathProxy<CustomerLedger>()(getCustomerActualTransactions.SelectPaths as any) as SPPI[];

export type jsonify<T> =
  T extends void ? null :
  T extends Promise<any> ? unknown :
  T extends Date ? string :
  T extends Map<infer K, infer V> ? [jsonify<K>, jsonify<V>][] :
  T extends Set<infer U> ? jsonify<U>[] :
  T extends string | number | boolean | null | undefined ? T :
  T extends [...any[]] ? number extends T["length"] ? jsonify<T[number]>[] : [...jsonifyTuple<T>] :
  T extends Array<infer U> ? jsonify<U>[] :
  T extends object ? { [K in keyof T]: jsonify<T[K]> } :
  unknown;

export type jsonifyTuple<T> = T extends [infer A, ...infer B] ? [jsonify<A>, ...jsonifyTuple<B>] : T extends [infer A] ? [jsonify<A>] : [];




type ModelName = keyof PrismaTypeMap["model"];
type ModelArgs<T extends ModelName> = PrismaTypeMap["model"][T]["operations"]["findMany"]["args"];
export function PrismaArgs<T extends ModelName, R extends readonly { key: string[], value: any }[]>(
  model: T, fn: (x: ObjectPathTree2<ModelArgs<T>, []>) => R
): ObjectPathTreeResult<R> {
  const paths = fn(PrismaPathWalker());
  const res = {};
  for (const path of paths) {
    let obj: any = res;
    for (let i = 0; i < path.key.length - 1; i++) {
      obj = obj[path.key[i]] = obj[path.key[i]] || {};
    }
    if (path.key.length)
      obj[path.key[path.key.length - 1]] = path.value;
  }
  return res;
}
interface ObjectArgInner<T> {
  <R extends readonly { key: string[], value: any }[]>(fn: (x: ObjectPathTree2<T, []>) => R): ObjectPathTreeResult<R>
}
export function ObjectArgs<T>(): ObjectArgInner<T> {
  return (fn) => {
    const paths = fn(ObjectPathWalker());
    const res = {};
    for (const path of paths) {
      let obj: any = res;
      for (let i = 0; i < path.key.length - 1; i++) {
        obj = obj[path.key[i]] = obj[path.key[i]] || {};
      }
      if (path.key.length)
        obj[path.key[path.key.length - 1]] = path.value;
    }
    // console.log(JSON.stringify(res));
    return res;
  }
}

type Filter<COLS, FILTER> = {
  [KEY in keyof COLS]:
  COLS[KEY] extends { key: [infer FIRST extends FILTER, ...infer REST], value: infer VALUE } ? REST extends [] ? never : { key: REST, value: VALUE } : never
}
const ColsSymbol: unique symbol = Symbol("Cols");
type ObjectPathTreeResult<COLS extends readonly { key: string[], value: any }[]> = {

  [K in keyof COLS as COLS[K] extends { key: [infer T, ...any] } ? T extends string ? T : never : never]:
  K extends number ? never : COLS[K] extends { key: [infer T, ...infer R], value: infer S } ? R extends [] ? S : ObjectPathTreeResult<Filter<COLS, T>> : never;
}

type ObjectPathTree2<T, R extends string[]> =
  {
    _: "select" extends keyof NN<T> ? ObjectPathTree2<NN<T>["select"], [...R, "select"]> : never;
    __: { key: R, value: true };
    ___<S extends T>(val: S): { key: R, value: S };
  } & ({ [K in KeyOf<NN<T>>]-?: ObjectPathTree2<NN<T>[K], [...R, K]> });

type KeyOf<T> = string & keyof T;

type NN<T> =
  T extends undefined ? never :
  T extends null ? never :
  T extends string ? never :
  T extends number ? never :
  T extends boolean ? never :
  T;
function ObjectPathWalker(path: string[] = []): any {
  return new Proxy<any>({}, {
    get(t: any, p: string, r) {
      if (p === "_") return ObjectPathWalker([...path, "select"]);
      if (p === "__") return ({ key: path, value: true });
      if (p === "___") return (val: any) => ({ key: path, value: val });
      return ObjectPathWalker([...path, p]);
    }
  });
}
function PrismaPathWalker(path: string[] = []): any {
  return new Proxy<any>({}, {
    get(t: any, p: string, r) {
      if (p === "_") return PrismaPathWalker([...path, "select"]);
      if (p === "__") return ({ key: path, value: true });
      if (p === "___") return (val: any) => ({ key: path, value: val });
      return PrismaPathWalker([...path, p]);
    }
  });
}

const t = PrismaArgs("CustomerLedger", x => [
  x._.id.__,
  x._.Amount.__,
  x._.line._.id.__,
  // x._.line._.invoiceLine._.rental._.InvoiceLines.orderBy.___({ line: { Date: "asc" } }),
] as const);
// type t3 = (typeof t)[typeof ColsSymbol];
t.select.Amount;
// type t2 = ObjectPathTree2<ModelArgs<"CustomerLedger">, []>;

type Hours = `${0 | 1}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}` | `2${0 | 1 | 2 | 3}`;


/** 
 * Converts between date string (with optional hour) and millis in our timezone.
 * 
 * Specially designed to work properly with new york time zone. 
 */
export const cubesDateTime = {
  parse: (date: string, hour: Hours = "00") =>
    DateTime.fromISO(`${date}T${hour}:00:00`, { zone: "America/New_York" }),
  formatValue: (date: number) => DateTime.fromMillis(date, { zone: "America/New_York" }).toISODate(),
  formatJSDate: (date: Date) => DateTime.fromJSDate(date, { zone: "America/New_York" }).toISODate(),
  format: (date: DateTime<true>) => date.toISODate(),
}

declare module "luxon" {
  interface DateTime<IsValid extends boolean = boolean> {
    cubesFormat: DateTime<IsValid>["toISODate"];
  }
}
DateTime.prototype.cubesFormat = DateTime.prototype.toISODate;