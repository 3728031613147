var styles = {
  "Sheet": "Polaris-Sheet",
  "Container": "Polaris-Sheet__Container",
  "Bottom": "Polaris-Sheet__Bottom",
  "enterBottom": "Polaris-Sheet--enterBottom",
  "enterBottomActive": "Polaris-Sheet--enterBottomActive",
  "exitBottom": "Polaris-Sheet--exitBottom",
  "exitBottomActive": "Polaris-Sheet--exitBottomActive",
  "Right": "Polaris-Sheet__Right",
  "enterRight": "Polaris-Sheet--enterRight",
  "enterRightActive": "Polaris-Sheet--enterRightActive",
  "exitRight": "Polaris-Sheet--exitRight",
  "exitRightActive": "Polaris-Sheet--exitRightActive"
};

export { styles as default };
