import { EventEmitter, Injector, ReactInjectable, useObservable } from "react-utils";
import { is, PaymentFormInput, PaymentFormKey, PaymentFormOutput } from "common";
import { AuthService } from "./service-auth";
import { distinctUntilChanged } from "rxjs";
import { asCustomer } from "../flags";
import { locationChange } from "./useNavigate";
export { useNavigate } from "./useNavigate";
const USE_LOCAL_STORAGE = false;

window.addEventListener("popstate", (event) => {
  console.log(event.state);
  urlChange.emit(location.href);
});

const urlChange = new EventEmitter<string>();

@ReactInjectable()
export class AppService {
  auth;
  url;
  paymentFormTokenId;
  storageAgreementResult;
  private _customerVerified: string | null = null;
  get customerVerified() {
    if (USE_LOCAL_STORAGE) return localStorage.getItem("cubes-email-verified");
    else return this._customerVerified;
  };
  set customerVerified(value: string | null) {
    if (USE_LOCAL_STORAGE) {
      if (typeof value === "string")
        localStorage.setItem("cubes-email-verified", value);
      else if (value === null)
        localStorage.removeItem("cubes-email-verified");
      else
        throw new Error("invalid value");
    } else {
      if (typeof value === "string" || value === null)
        this._customerVerified = value
      else
        throw new Error("invalid value");
    }
  };
  customerVerifiedChange = new EventEmitter<string | null>();

  loginToken: { token?: string, sig?: string, key?: string } | undefined;

  constructor(injector: Injector) {
    this.auth = injector.get(AuthService);
    let lastVerifiedValue = this.customerVerified;
    setInterval(() => {
      const value = this.customerVerified;
      if (value !== lastVerifiedValue) {
        lastVerifiedValue = value;
        this.customerVerifiedChange.emit(value);
      }
    }, 1000);

    this.url = new URL(location.href);


    this.loginToken = {};
    this.loginToken.key = this.url.pathname.slice(1);


    if (this.url.pathname === "/storage-agreement-result") {
      const status = this.url.searchParams.get("event") ?? "";
      // signing_complete	The recipient has signed the document.
      // viewing_complete	The recipient did not need to sign but has completed the viewing ceremony.
      const complete = status.inArray(["signing_complete", "viewing_complete"]);
      // fax_pending	Recipient has a fax pending.
      const fax = status === "fax_pending";
      // exception	An exception has occurred on the server during the signing session.
      // ttl_expired	The token was not used within the timeout period or the token has already been accessed.
      const error = status.inArray(["exception", "session_timeout", "ttl_expired"]);
      // session_timeout	The recipient did not sign the document in time. The timeout is set to 20 minutes.
      const timed_out = status === "session_timeout";
      // cancel	The recipient decided to finish later.
      // decline	The recipient declined to sign the document.
      const incomplete = status.inArray(["cancel", "decline"]);

      this.storageAgreementResult = { complete, fax, error, incomplete, timed_out };
      // this.storageAgreementResult =
      //   complete ? "complete" as const :
      //     fax ? "fax" as const :
      //       error ? "error" as const :
      //         incomplete ? "incomplete" as const :
      //           "unknown" as const;
    }


    // this is the callback for the credit card form
    if (this.url.searchParams.has("token-id")) {
      this.paymentFormTokenId = this.url.searchParams.get("token-id");
      this.url.searchParams.delete("token-id");
    }

    if (this.url.searchParams.has("token")) {
      this.loginToken.token = this.url.searchParams.get("token")!;
      this.url.searchParams.delete("token");
    }

    if (this.url.searchParams.has("sig")) {
      this.loginToken.sig = this.url.searchParams.get("sig")!;
      this.url.searchParams.delete("sig");
    }

    if (!this.loginToken.key || !this.loginToken.token || !this.loginToken.sig) {
      this.loginToken = undefined;
    }

    history.replaceState(null, "", this.url.href);

    this.auth.authChange.pipe(distinctUntilChanged()).subscribe(() => this.updateStatus());

  }

  async handleStorageAgreementLink() {

    if (!this.loginToken || !this.loginToken.key || !this.loginToken.token || !this.loginToken.sig) return;
    if (!is<{ key: string, token: string, sig: string }>(this.loginToken, true)) throw new Error("literally ok true");
    const [error, res] = await this.postAppState("get-storage-agreement-link", { ...this.loginToken, sendEmail: true }).try();
    if (error) return;
    this.customerVerified = JSON.stringify(this.loginToken);
    this.loginToken = undefined;
  }

  status: PaymentFormOutput["status"] | undefined;
  statusChange = new EventEmitter<PaymentFormOutput["status"] | Error>();
  statusError: AppError | undefined;
  /** whether the last postAppState got ID_TOKEN_INVALID as a response */
  tokenInvalid = false;

  get hasInfo() { const res = this.status; return !!res && !!res.ccNumber && !!res.ccExpiry || !!res && !!res.ckAccount && !!res.ckRouting; }
  get isCard() { const res = this.status; return !!res && !!res.ccNumber; }

  async updateStatus() {
    console.log("updateStatus", this.auth.authStatus, this);
    alert
    if (this.auth.authStatus === "authenticated") {
      const [error, res] = await this.postAppState("status", {}).try();
      if (res && res.ccExpiry?.length === 4) res.ccExpiry = `${res.ccExpiry.slice(0, 2)}/${res.ccExpiry.slice(2, 4)}`;
      console.log("getStatus", error, res);
      this.statusError = error;
      this.status = res;
    } else {
      this.status = undefined;
      this.statusError = undefined;
    }
    this.statusChange.emit(this.statusError || this.status);
  }
  get gettingStartedComplete() {
    if (!this.status) return false;
    const {
      Email,
      EmailVerified,
      StorageAgreementCompleted,
      PaymentInfoValid,
      Name, Address, Phone,
    } = this.status;
    const validname = (Name ?? "").trim().length > 0;

    return !!Email && !!EmailVerified && !!StorageAgreementCompleted && !!validname;

  }
  async postAppState<M extends PaymentFormKey>(key: M, data: PaymentFormInput[M], allowReset = false) {

    // token requests still require the user to be logged in
    const res = await fetch(location.origin + "/api/" + key, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "id-token": await this.auth.getAuthToken(),
        ...asCustomer ? { "x-cubes-as-customer": asCustomer } : {},
      },
    });

    const body = await res.text();

    const json = (() => { try { return JSON.parse(body) } catch (e) { return undefined } })();

    if (body === "AS_CUSTOMER_NOT_ALLOWED") {
      if (asCustomer)
        alert("This is only for customers and cannot be done when viewing the customer page as a branch");
      else
        alert("For some reason, the system think you are a branch user. You should probably let us know.");
    }

    if (body === "ID_TOKEN_INVALID") {
      if (asCustomer) {
        alert("Your session has expired. You can return here from the branch customer edit page.");
        sessionStorage.clear();
        location.href = location.origin;
      }
    }



    if (res.status !== 200) {
      const error = new AppError(res, body);
      console.log(error);
      throw error;
    } else {
      return json as PaymentFormOutput[M] | undefined;
    }

  }
}

export class AppError extends Error {
  constructor(public res: Response, public body: string) {
    super(`${res.status} ${res.statusText}: ${body}`);
  }
}


export function useLocation() {
  useObservable(locationChange);
  return location;
}