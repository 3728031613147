import "./globalThisPolyfill";
export { };
import { OverloadsToTuple as innerOverloadsToTuple } from "./OverloadToTuple";



declare const ParseJSON: unique symbol;

interface ArrayExtensions<T> {
  /** Transform the array into something else to continue chaining */
  lift<R>(fn: (a: T[]) => R): R;
  /** Filters the array, separating them into two arrays using +valueIsS */
  partition: {
    <S extends T>(predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: any): [Exclude<T, S>[], S[]];
    (predicate: (value: T, index: number, array: T[]) => any, thisArg?: any): [T[], T[]];
  }

  first(): T | undefined;
  last(): T | undefined;

  contains(a: T): a is T;
  contains(a: any): a is T

  // count: Array<T>["filter"];

  /**
   * Returns the number of elements of an array that meet the condition specified in a callback function.
   * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each element in the array.
   * @param thisArg An object to which the this keyword can refer in the predicate function. If thisArg is omitted, undefined is used as the this value.
   */
  count<S extends T>(predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: any): number;
  /**
   * Returns the number of elements of an array that meet the condition specified in a callback function.
   * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each element in the array.
   * @param thisArg An object to which the this keyword can refer in the predicate function. If thisArg is omitted, undefined is used as the this value.
   */
  count(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): number;

  indexOfOrNull(a: T): number | null;

  // /**
  //  * Calls a predicate and (if the predicate returns truthy) a transform function on each element of an array, and returns an array that contains the results.
  //  * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each element in the array.
  //  * @param transform A function that accepts up to three arguments. The map method calls the callbackfn function one time for each element in the array.
  //  * @param thisArg An object to which the this keyword can refer in the callback and predicate functions. If thisArg is omitted, undefined is used as the this value.
  //  */
  // filterMap<T, U extends T, V>(
  //   this: T[],
  //   predicate: (value: T, index: number, array: U[]) => value is U,
  //   transform: (value: U, index: number, array: T[]) => V,
  //   thisArg?: any
  // ): V[]

  // /**
  //  * Calls a defined callback function on each element of an array, and returns an array that contains the results.
  //  * @param callbackfn A function that accepts up to three arguments. The map method calls the callbackfn function one time for each element in the array.
  //  * @param thisArg An object to which the this keyword can refer in the callbackfn function. If thisArg is omitted, undefined is used as the this value.
  //  */
  // // The declaration for this needs to be commented out in lib.es5.d.ts
  // map: {
  //     readonly callback: typeof symbols.callback;
  //     <U, THIS extends { [Array.prototype.map.callback]: (value: T, index: number, array: T[]) => U, } = never>(this: T[], callbackObj: THIS): U[];
  //     <U, THIS = never>(this: T[], callbackfn: (this: THIS, value: T, index: number, array: T[]) => U, thisArg?: THIS): U[];
  // };
}

declare global {


  type OverloadsToTuple<T> = innerOverloadsToTuple<T>;
  interface CallableFunction {
    /**
     * For a given function, creates a bound function that has the same body as the original function.
     * The this object of the bound function is associated with the specified object, and has the specified initial parameters.
     * @param thisArg The object to be used as the this object.
     * @param args Arguments to bind to the parameters of the function.
     */
    bind<T, A extends any[], B extends any[], R>(this: (this: T, ...args: [...A, ...B]) => R, thisArg: T, ...args: A): (...args: B) => R;
  }
  // const console: never;
  interface ObjectConstructor {
    // keys: <T>(a: T) => (keyof T)[]
    keys<T extends string>(a: { [K in T]: any }): T[]
    entries<T>(o: ArrayLike<T>): [string, T][];
    entries<T>(o: T): [string & keyof T, T[string & keyof T]][];
  }

  const SERVERSIDE: false;
  interface ObjectConstructor {
    // keys: <T>(a: T) => (keyof T)[]
    keys<T extends string>(a: { [K in T]: any }): T[];
    walk<T,
      A extends keyof T,
      B extends keyof T[A],
    >(
      obj: T,
      a?: A,
      b?: B
    ): T[A][B];
    walk<T,
      A extends keyof T,
      B extends keyof T[A],
      C extends keyof T[A][B],
    >(
      obj: T,
      a?: A,
      b?: B,
      c?: C
    ): T[A][B][C];
    walk<T,
      A extends keyof T,
      B extends keyof T[A],
      C extends keyof T[A][B],
      D extends keyof T[A][B][C],
    >(
      obj: T,
      a?: A,
      b?: B,
      c?: C,
      d?: D
    ): T[A][B][C][D];
    walk<T,
      A extends keyof T,
      B extends keyof T[A],
      C extends keyof T[A][B],
      D extends keyof T[A][B][C],
      E extends keyof T[A][B][C][D],
    >(
      obj: T,
      a?: A,
      b?: B,
      c?: C,
      d?: D,
      e?: E
    ): T[A][B][C][D][E];
    walk<T,
      A extends keyof T,
      B extends keyof T[A],
      C extends keyof T[A][B],
      D extends keyof T[A][B][C],
      E extends keyof T[A][B][C][D],
      F extends keyof T[A][B][C][D][E]
    >(
      obj: T,
      a?: A,
      b?: B,
      c?: C,
      d?: D,
      e?: E,
      f?: F
    ): T[A][B][C][D][E][F];
    walk<T,
      A extends keyof T,
      B extends keyof T[A],
      C extends keyof T[A][B],
      D extends keyof T[A][B][C],
      E extends keyof T[A][B][C][D],
      F extends keyof T[A][B][C][D][E],
      G extends keyof T[A][B][C][D][E][F]
    >(
      obj: T,
      a?: A,
      b?: B,
      c?: C,
      d?: D,
      e?: E,
      f?: F,
      g?: G
    ): T[A][B][C][D][E][F][G];
    walk<T,
      A extends keyof T,
      B extends keyof T[A],
      C extends keyof T[A][B],
      D extends keyof T[A][B][C],
      E extends keyof T[A][B][C][D],
      F extends keyof T[A][B][C][D][E],
      G extends keyof T[A][B][C][D][E][F],
      H extends keyof T[A][B][C][D][E][F][G],
    >(
      obj: T,
      a?: A,
      b?: B,
      c?: C,
      d?: D,
      e?: E,
      f?: F,
      g?: G,
      h?: H
    ): T[A][B][C][D][E][F][G][H];
  }


  interface ReadonlyArray<T> extends ArrayExtensions<T> {

  }

  interface Array<T> extends ArrayExtensions<T> {

  }

  interface JSON {

    /**
     * Converts a JavaScript value to a JavaScript Object Notation (JSON) string and back into an object.
     * @param value A JavaScript value, usually an object or array, to be cloned.
     * @param replacer An array of strings and numbers that acts as an approved list for selecting the object properties that will be stringified, or a function that transforms the results.
     * @param reviver A function that transforms the results. This function is called for each member of the object.
     * If a member contains nested objects, the nested objects are transformed before the parent object is.
     */
    clone<T>(value: T, replacer?: (number | string)[] | null | ((this: any, key: string, value: any) => any), reviver?: (this: any, key: string, value: any) => any): T;
    stringify<T>(value: T): JSONString<T>;
    parse<T = any>(text: JSONString<T>): T;
  }


  type JSONString<T> = string & { [ParseJSON]: () => T; }

  interface String {
    startsWith<T extends string>(a: T): this is `${T}${string}`
    endsWith<T extends string>(a: T): this is `${string}${T}`
    surroundsWith<P extends string, S extends string>(a: P, b: S): this is `${P}${string}${S}`;
    toDate(): Date;
    inArray<T extends string, R extends T[]>(this: T, a: R): this is R[number];
    inArray<T extends string, R extends readonly T[]>(this: T, a: R): this is R[number];
  }
  interface Date {
    toDate(): Date;
  }
}

JSON.clone = (a, b, c) => JSON.parse(JSON.stringify(a, b as any), c);
Object.defineProperty(JSON, "ParseJSON", {
  value: Symbol("ParseJSON"),
  enumerable: false,
  configurable: true,
  writable: false,
});

Array.prototype.lift = function pipe(fn) { return fn(this); }

Array.prototype.partition = function partition<S, T>(this: T[], fn: (value: any, index: number, array: any[]) => any, thisArg = undefined) {
  const res = [[], []] as [Exclude<T, S>[], S[]];
  this.forEach((e, i, a) => res[+!!fn.call(thisArg, e, i, a)].push(e as never));
  return res;
}

Array.prototype.first = function first<T>(this: T[]) {
  return this[0];
}

Array.prototype.last = function last<T>(this: T[]) {
  return this[this.length - 1];
}

//@ts-ignore
String.prototype.surroundsWith = function surroundsWith(a, b) { return this.startsWith(a) && this.endsWith(b) }
//@ts-ignore
String.prototype.inArray = function inArray(a: any[]) { return a.includes(this) };

String.prototype.toDate = function toDate() { return new Date(this.valueOf()); }
Date.prototype.toDate = function toDate() { return new Date(this.valueOf()); }

Array.prototype.lift = function pipe(fn) { return fn(this); }

Array.prototype.partition = function partition<S, T>(this: T[], fn: (value: any, index: number, array: any[]) => any, thisArg = undefined) {
  const res = [[], []] as [Exclude<T, S>[], S[]];
  this.forEach((e, i, a) => res[+!!fn.call(thisArg, e, i, a)].push(e as never));
  return res;
}

Array.prototype.first = function first<T>(this: T[]) {
  return this[0];
}

Array.prototype.last = function last<T>(this: T[]) {
  return this[this.length - 1];
}

Array.prototype.contains = function contains<T>(this: T[], val: T): val is T {
  return this.indexOf(val) > -1;
}

Array.prototype.count = function count<T>(this: T[], fn: (value: T, index: number, array: T[]) => any, thisArg = undefined) {
  return this.reduce((n, e, i, a) => n + (+!!fn.call(thisArg, e, i, a)), 0);
}

Array.prototype.indexOfOrNull = function indexOfOrNull<T>(this: T[], val: T): number | null {
  const i = this.indexOf(val);
  return i === -1 ? null : i;
}


Object.walk = function walk(obj: any, ...rest: any[]) { return rest.reduce((n, e) => n ? n[e] : n, obj as any); }

declare global {
  interface Promise<T> {
    try<E = any>(): Promise<[undefined, T] | [E, undefined]>;
  }
}
Promise.prototype.try = function () {
  return this.then(e => [undefined, e] as const, e => [e, undefined] as const);
}

declare global {
  interface Map<K, V> {
    /**
     * Shortcut for:
     * ```
     * map.get(key) ?? map.set(key, defaultValue).get(key)
     * ```
     * @param key 
     * @param defaultValue 
     */
    getForced(key: K, defaultValue: V): V;
  }
}

Map.prototype.getForced = function getForced<K, V>(key: K, defaultValue: V) {
  return this.get(key) ?? this.set(key, defaultValue).get(key)!
}



async function _try_await_(fn: () => Promise<any>) {
  try {
    return [undefined, await fn()];
  } catch (e) {
    return [new TryError(e), undefined];
  }
}

function _try_(fn: () => any) {
  try {
    return [undefined, fn()];
  } catch (e) {
    return [new TryError(e), undefined];
  }
}

class _TryError extends Error {
  inner: any;
  constructor(inner: any) {
    // Coercing to a string won't work for anything which was created with Object.create(null).
    let message;
    try {
      message = `${inner}`;
    } catch (e) {
      message = "[unknown]";
    }
    super(message);
    this.name = "TryError";
    this.inner = inner;
  }
}


declare global {
  // eslint-disable-next-line no-var
  var try_await_: typeof _try_await_;
  // eslint-disable-next-line no-var
  var try_: typeof _try_;
  // eslint-disable-next-line no-var
  var TryError: typeof _TryError;
}

globalThis.try_await_ = _try_await_;
globalThis.try_ = _try_;
globalThis.TryError = _TryError;

