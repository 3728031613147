import { is, jsonify, Modes, ok, Prisma, TABLE_NAMES, truthy } from "common";
import { DataPage } from "./question-dialog";
import { QuestionGroup } from "./question-base";
import { Badge, BlockStack, BlockStackProps, Box, BoxProps, Button, ButtonGroup, DataTable, Divider, Icon, InlineStack, Loading, ModalProps, Spinner, Text } from "@shopify/polaris/index";
import { PropsWithChildren, useCallback, useLayoutEffect, useMemo, useRef, useState, useSyncExternalStore } from "react";
import { ButtonAwait, ConfirmationService, emitGlobalRefresh, EventEmitter, globalConfirmation, globalMessage, Injector, ModalHostEvents, StatusBadge, useAngular, useAsyncEffect, useObservable, useObserver, useRefresh } from "react-utils";
import { InfoIcon } from "@shopify/polaris-icons";
import { UIService } from "./ui.service";
import { DataService } from "data-service";
import { DataListColumn } from "./question-classes";
import { DateTime } from "luxon";
import { dollarsToCents } from "../pages/page-table-list";
/** shenanigans: this changes the type into what we would see in client */
function jsonify<T>(obj: T): jsonify<T> {
  return obj as any;
}
/** This will throw if where does not return exactly 1 row. */
export async function showTxnInfoModal({ where, data, showVoidRestore }: {
  data: DataService;
  where: Prisma.TransactionWhereInput;
  showVoidRestore: boolean;
}) {



  showModal({
    useTitle: () => "Transaction Info",
    useOpen: () => true,
    useDirty: () => false,
    onSave: async () => { },
    onDiscard: async () => { },
    useChildren: () => {
      const { get } = useAngular();
      const data = get(DataService);

      const { result: txn, loading } = useAsyncEffect(async () => {
        const txns = jsonify(await data.prisma.transaction.findMany({
          where,
          include: {
            customerLedgerLine: { select: { Amount: true, customer: { select: { id: true, billing: true } } } },
            branchLedgerLine: { select: { Amount: true, branch: { select: { id: true, DisplayName: true, } } } },
            divisionLedgerLine: { select: { Amount: true, division: { select: { id: true, Name: true } } } },
            centralLedgerLine: { select: { Amount: true } },
            ownerLedgerLine: { select: { Amount: true, owner: { select: { id: true, billing: true } } } },
            invoiceLine: {
              include: {
                item: { select: { ItemType: true, ItemName: true, Taxable: true } },
                rental: { include: { unit: { select: { Name: true, unitType: { select: { Name: true } } } } } },
                promotion: { select: { Title: true } },
                branch: { select: { DisplayName: true } },
                owner: { select: { billing: { select: { Name: true } } } },
              }
            },
            paymentLine: true,
            salesTaxLedgerLine: { include: { branch: { select: { id: true, DisplayName: true } } } },
          }
        }));
        ok(txns.length === 1, "Expected one transaction");
        return txns[0];
      });

      if (loading) return <Box padding="800"><BlockStack align="center"><InlineStack align="center"><Spinner /></InlineStack></BlockStack></Box>;
      if (!txn) return null;


      const salesTaxBranch = txn.salesTaxLedgerLine[0]?.branch;
      const ledgers = [
        (txn.paymentLine && txn.customerLedgerLine) && [
          'Customer (A/R)',
          txn.customerLedgerLine.customer && <Button variant="plain" url={"/Customer/edit/" + txn.customerLedgerLine.customer.id}>{txn.customerLedgerLine.customer.billing?.Name ?? ""}</Button>,
          txn.customerLedgerLine.Amount
        ],
        txn.branchLedgerLine && [
          'Branch (A/P)',
          txn.branchLedgerLine.branch && <Button variant="plain" url={"/Branch/edit/" + txn.branchLedgerLine.branch.id}>{txn.branchLedgerLine.branch.DisplayName}</Button>,
          txn.branchLedgerLine.Amount
        ],
        txn.divisionLedgerLine && [
          'Division (A/P)',
          txn.divisionLedgerLine.division?.Name,
          txn.divisionLedgerLine.Amount
        ],
        txn.centralLedgerLine && ['Central', '', txn.centralLedgerLine?.Amount],
        txn.ownerLedgerLine && [
          'Owner (A/P)',
          txn.ownerLedgerLine?.owner && <Button variant="plain" url={"/Owner/edit/" + txn.ownerLedgerLine.owner.id}>{txn.ownerLedgerLine.owner.billing?.Name ?? "Link to Owner"}</Button>,
          txn.ownerLedgerLine?.Amount
        ],
        // branches are the brick and morter location which the sales tax is tied to
        (txn.invoiceLine?.item.Taxable || txn.salesTaxLedgerLine.length) && [
          'Sales Tax (A/P)',
          new Set(txn.salesTaxLedgerLine.map(x => x.branch?.DisplayName)).size > 1 ? "Multiple" :
            <Button variant="plain" url={"/Branch/edit/" + salesTaxBranch?.id}>{salesTaxBranch?.DisplayName}</Button>,
          txn.salesTaxLedgerLine.reduce((acc, curr) => acc + curr.Amount, 0)
        ],
      ].filter(truthy) as [string, string | JSX.Element, number | null][];
      const ledgerTotal = ledgers.reduce((acc, curr) => acc + (curr[2] ?? 0), 0);
      const dollar = (amount: number | null | undefined) => DataListColumn.textCubesDinero(amount);
      const date = (date: string | null | undefined) => !date ? null : DateTime.fromISO(date, { zone: "America/New_York" }).toJSDate().toLocaleDateString();
      const statusCol = new DataListColumn("test", 0, DataListColumn.getFieldType("PaymentLine", x => x.PaymentStatus.__));

      const voidRestoreButton = showVoidRestore && useVoidRestoreButton({
        data, lineID: txn.id, isPayment: !!txn.paymentLine, isVoided: !!txn.VoidSince
      });
      const isRental = txn.invoiceLine?.item.ItemType === 'Rental';
      const invoiceLineData = txn.invoiceLine ? [
        ["Item", txn.invoiceLine.item.ItemName, txn.invoiceLine.item.ItemType],
        txn.invoiceLine.rental && ["Rental", txn.invoiceLine.rental.unit.Name, txn.invoiceLine.rental.unit.unitType.Name],
        txn.invoiceLine.item.ItemType === 'Rental' && ["Promotion", txn.invoiceLine.promotion?.Title, ''],
      ].filter(truthy) : [];

      return (<>
        {txn.paymentLine && <BlockStackBox>
          <ButtonGroup >
            {data.status.isAdmin && <ButtonAwait onClick={async () => {
              await data.server.serverCheckPaymentStatus({ lineID: txn.id });
            }}>Check Status</ButtonAwait>}
          </ButtonGroup>
          <InlineStack gap="400" align="space-between">
            <Text as="h1" variant="headingMd">Payment Line</Text>
            <Text as="h2" variant="bodyMd">Date: {txn.Date}</Text>
            {statusCol.markup?.(txn.paymentLine.PaymentStatus)}
          </InlineStack>
          <Text as="h2" variant="headingMd">Description</Text>
          <Box paddingInline="200">
            <BlockStack>
              {txn.Description?.split("\n").map((line, i) => <Text key={i} as="p" variant="bodyMd">{line}</Text>)}
            </BlockStack>
          </Box>
          <Text as="h2" variant="headingMd">Payment Info</Text>
          <DataTable
            columnContentTypes={['text', 'text', 'text']}
            headings={[]}
            rows={[
              ...txn.paymentLine.PaymentTxnType?.inArray(["greenpay_cc", "greenpay_ck"]) ? [
                ['Processor', "GreenPay"],
                ['Payment Type', txn.paymentLine.PaymentTxnType === "greenpay_cc" ? "Credit Card" : "ACH"],
                ['Amount', dollar(txn.paymentLine.PaymentAmount)],
                ['Fee', dollar(txn.paymentLine.PaymentFee)],
                // ['Approved', date(txn.paymentLine.PaymentApproved)],
                // ['Cleared', date(txn.paymentLine.PaymentCleared)],
              ] : [],
              // this is probably a refund, but just include whatever it is
              ...invoiceLineData,
            ].filter(truthy)}
            increasedTableDensity
          />
          <Text as="h2" variant="headingMd">Ledger</Text>
          <DataTable
            columnContentTypes={['text', 'text', 'numeric']}
            headings={[]}
            rows={ledgers.map(([name, account, amount]) => [name, account, DataListColumn.textCubesDinero(amount)])}
            increasedTableDensity
          />
          <Text as="h2" variant="headingMd">Transaction ID</Text>
          <Box paddingInline="200">
            <BlockStack>
              <Text as="p" variant="bodyMd">{txn.paymentLine.txnID}</Text>
            </BlockStack>
          </Box>
        </BlockStackBox>}
        {txn.invoiceLine && !txn.paymentLine && <BlockStackBox>
          <ButtonGroup >
            {voidRestoreButton}
          </ButtonGroup>
          <InlineStack gap="400" align="space-between">
            <Text as="h1" variant="headingMd">Invoice Line</Text>
            <Text as="h2" variant="bodyMd">Date: {txn.Date}</Text>
            <StatusBadge value={!!txn.invoiceLine.paidOn}
              trueTone="success"
              falseTone="critical"
              trueLabel={"Paid On: " + txn.invoiceLine.paidOn}
              falseLabel="Unpaid"
            />
          </InlineStack>
          <DataTable
            columnContentTypes={['text', 'text', 'numeric']}
            headings={[]}
            rows={[
              txn.customerLedgerLine?.customer && [
                "Customer",
                <Button variant="plain" url={"/Customer/edit/" + txn.customerLedgerLine.customer.id}>
                  {txn.customerLedgerLine.customer.billing?.Name ?? "(unnamed)"}
                </Button>,
                <Text as="span" variant="bodyMd" fontWeight="bold">{DataListColumn.textCubesDinero(txn.customerLedgerLine.Amount)}</Text>
              ],
              ...invoiceLineData,
            ].filter(truthy)}
            increasedTableDensity
          />
          <Text as="h2" variant="headingMd">Payout Distribution</Text>
          <DataTable
            columnContentTypes={['text', 'text', 'numeric']}
            headings={[]}
            rows={ledgers.map(([name, account, amount]) => [name, account, DataListColumn.textCubesDinero(amount)])}
            increasedTableDensity
            showTotalsInFooter
            totals={['',
              (ledgerTotal !== txn.customerLedgerLine?.Amount
                && <Badge size="medium" progress="incomplete" tone="critical" >Ledger doesn't match</Badge>),
              DataListColumn.textCubesDinero(ledgerTotal)
            ]}
          />
          <Text as="h2" variant="headingMd">Payment Fees</Text>
          {txn.invoiceLine.paidOn ? <DataTable
            columnContentTypes={['text', 'text', 'numeric']}
            headings={[]}
            rows={[
              ["Branch", txn.branchLedgerLine?.branch?.DisplayName, dollar(txn.invoiceLine.BranchPaymentFee)],
              ["Owner", txn.ownerLedgerLine?.owner?.billing?.Name, dollar(txn.invoiceLine.OwnerPaymentFee)],
            ]}
            increasedTableDensity
          /> : <Badge size="medium" progress="incomplete" tone="critical" >Unpaid</Badge>}
        </BlockStackBox>}
      </>
      );
    }
  });


}

const useVoidRestoreButton = ({
  data,
  lineID,
  isPayment,
  isVoided,
}: {
  data: DataService;
  lineID: string;
  isPayment: boolean;
  isVoided: boolean;
}) => {
  if (isPayment && !data.status.isArlen) return null;

  const { voidTitle, voidMessage, restoreTitle, restoreMessage } = isPayment ? {
    voidTitle: "Void Payment Line",
    voidMessage: "This will not void the actual payment. Only do this if the payment has already been voided in the transaction gateway.",
    restoreTitle: "Restore Payment Line",
    restoreMessage: "This will not restore the actual payment. Only do this if the payment has not been voided in the transaction gateway."
  } : {
    voidTitle: "Void Invoice Line",
    voidMessage: "Are you sure you want to void this invoice line?",
    restoreTitle: "Restore Invoice Line",
    restoreMessage: "Are you sure you want to restore this invoice line?"
  };

  return isVoided ? <ButtonAwait onClick={async () => {
    await prompt(restoreTitle, restoreMessage, async () => {
      await data.server.serverRestoreTransaction({ lineID });
      globalMessage.add({ severity: 'success', summary: restoreTitle, detail: `Restore transaction succeeded!` });
    });
  }}>{restoreTitle}</ButtonAwait> : <ButtonAwait onClick={async () => {
    await prompt(voidTitle, voidMessage, async () => {
      await data.server.serverVoidTransaction({ lineID });
      globalMessage.add({ severity: 'success', summary: voidTitle, detail: `Void transaction succeeded!` });
    });
  }}>{voidTitle}</ButtonAwait>;

};

function BlockStackBox({ children, padding = "300", gap = "300" }: PropsWithChildren<{ padding?: BoxProps["padding"], gap?: BlockStackProps["gap"] }>) {
  return (
    <Box padding={padding}>
      <BlockStack gap={gap}>
        {children}
      </BlockStack>
    </Box>
  )
}
async function prompt<T>(header: string, message: string, onConfirm: () => Promise<T>): Promise<T | null> {
  return await new Promise<T | null>((resolve, reject) => {
    globalConfirmation.confirm({
      message,
      header,
      // icon: 'pi pi-info-circle',
      accept: () => {
        Promise.resolve()
          .then(() => onConfirm())
          .then(resolve, reject);
      },
      reject: () => {
        resolve(null);
      }
    })

  });
};
function showModal({ useChildren, useOpen, useTitle, onDiscard, onSave, useDirty }: {
  useChildren: () => JSX.Element | null;
  useTitle: () => string;
  useOpen: () => boolean;
  onSave: () => Promise<void>;
  onDiscard: () => Promise<void>;
  useDirty: () => boolean;
}) {

  const render = (): ModalProps => {
    const { get } = useAngular();
    const ui = get(UIService);
    const data = get(DataService);

    const isDirty = false;
    const onClose = useCallback(async () => {
      render.subs.unsubscribe();
    }, []);

    return {
      onClose,
      open: useOpen(),
      title: useTitle(),
      loading: false,
      children: (<>
        {useChildren()}
        <Divider />
        {useDirty() ? <Box padding="400">
          <Box width="100%" padding="200" background="bg-surface-caution" borderRadius="200" >
            <InlineStack gap="400" align="space-between">
              <InlineStack gap="400" align='start' blockAlign='center'>
                <Icon source={InfoIcon} tone='caution' />
                <Text as="p">Unsaved Changes</Text>
              </InlineStack>
              <InlineStack gap="400" align='end'>
                <ButtonAwait onClick={onDiscard} >Discard</ButtonAwait>
                <ButtonAwait variant='primary' onClick={onSave}>Save</ButtonAwait>
              </InlineStack>
            </InlineStack>
          </Box>
        </Box> : <Box padding="400">
          <InlineStack gap="400" align='end'>
            <Button variant='primary' onClick={onClose}>Close</Button>
          </InlineStack>
        </Box>}
      </>),
    };
  };
  // this is just for typing, it will get replaced
  render.subs = new EventEmitter();
  ModalHostEvents.emit(render);
}