
// The polyfill starts here.
(function() {
  if (typeof globalThis === 'object') return;
  Object.defineProperty(Object.prototype, '__magic__', {
      get: function() {
          return this;
      },
      configurable: true
  });
  //@ts-expect-error the __magic__ variable is temporary
  __magic__.globalThis = __magic__;
  //@ts-expect-error the __magic__ variable is temporary
  delete Object.prototype.__magic__;
}());
// The polyfill ends here.
