var styles = {
  "ActionContainer": "Polaris-Autocomplete-MappedAction__ActionContainer",
  "Action": "Polaris-Autocomplete-MappedAction__Action",
  "destructive": "Polaris-Autocomplete-MappedAction--destructive",
  "selected": "Polaris-Autocomplete-MappedAction--selected",
  "disabled": "Polaris-Autocomplete-MappedAction--disabled",
  "Prefix": "Polaris-Autocomplete-MappedAction__Prefix",
  "Suffix": "Polaris-Autocomplete-MappedAction__Suffix",
  "Content": "Polaris-Autocomplete-MappedAction__Content",
  "Text": "Polaris-Autocomplete-MappedAction__Text",
  "ContentWrap": "Polaris-Autocomplete-MappedAction__ContentWrap"
};

export { styles as default };
