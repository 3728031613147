import { EventEmitter, Injector } from "@angular/core";
import { Button, ButtonProps, Checkbox, ModalProps, Spinner } from "@shopify/polaris";
import React, { useCallback, useSyncExternalStore, useRef, useEffect, useLayoutEffect, useState, useMemo, DependencyList } from "react";
import { from, NEVER, Observable, Observer, Subscription } from "rxjs";
import i18n from '@shopify/polaris/locales/en.json';
import { useLocation } from "react-router";
import { ReactInjector, useAngular } from "./ReactInjector";
import { ConfirmationService, MessageService } from "./services";
import { } from "common";
import { DateTime } from "luxon";


export * from "./ReactInjector";
export * from "./CardTitle";
export * from "./Pip";
export * from "./services";
export * from "./useAsyncEffect";
export * from "./useDispatch";
export * from "./useEmptyStates";
export * from "./useEventEmitter";
export * from "./useLayoutEffectWatcher";
export * from "./useZoneNavigate";
export * from "./ModalWrapper";


export const rootInjector = new ReactInjector(undefined, []);
export const globalMessage = rootInjector.get(MessageService);
export const globalConfirmation = rootInjector.get(ConfirmationService);

/** A render function which gets called inside a function component. */
export const ModalHostEvents = new EventEmitter<() => ModalProps>();

export { Injector, EventEmitter };

const globalRefresh = new EventEmitter<object>();
export const RefreshContext = React.createContext(globalRefresh);
/** Returns an observable */
export function useRefresh() { return React.useContext(RefreshContext); }
globalRefresh.subscribe((e) => {
  if (!e) {
    console.error("globalRefresh came through without a value. Repeating with a value.");
    globalRefresh.emit({});
  } else {
    console.log("globalRefresh");
  }
});

export function emitGlobalRefresh() {
  globalRefresh.emit({});
}

export function useRoute() {
  const location = useLocation();
  const [, table, view2, id] = location.pathname.split("/");
  return { table, view2, id };
}


declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "vr": React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>;
      "wrap": React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    }
  }
}

export function AppLinks(props: any) {
  return <span {...props}></span>
}
export { i18n };


type un = unknown;
/**
 * Subscribes useSyncExternalStore to the observable.
 * 
 * @param obs The observable to subscribe. Follows switchAll behavior when the instance changes. Subscribes to a Promise using `from(obs)`.
 * @param initialValue Passed to useRef and returned until the first emission from the observable.
 * @param map Maps emissions from the observable but not the initial value.
 * This is called to map the observable to subsequent values. If not specified, the latest emission is returned directly. 
 * It is not a dependancy, but is passed into the useMemo call when the observable instance changes. 
 * @returns 
 */
export function useObservable<T>(obs: Observable<T> | Promise<T> | undefined): T | undefined;
export function useObservable<T>(obs: Observable<T> | Promise<T> | undefined, initialValue: T): T;
export function useObservable<T, I>(obs: Observable<T> | Promise<T> | undefined, initialValue: I): T | I;
export function useObservable<T, U>(obs: Observable<T> | Promise<T> | undefined, initialValue: U, map?: (value: T) => U): U;
export function useObservable(obs: Observable<un> | Promise<un> | undefined, initialValue?: un, map?: (value: un) => un): un {
  const ref = useRef(initialValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doCheck = useMemo(() => subscribeEffectBody(obs === undefined ? NEVER : obs instanceof Promise ? from(obs) : obs, ref, map), [obs]);
  return useSyncExternalStore(doCheck, () => ref.current);
}

/**
 * (`useLayoutEffect`) Subscribes the observer to the observable, unsubscribing when either of them change. 
 * 
 * @param obs The observable to subscribe. Subscribes to a Promise using `from(obs)`.
 * @param observerOrNext An observer object or a callback function.
 */
export function useObserver<T>(obs: Observable<T> | Promise<T>, observerOrNext?: Partial<Observer<T>> | ((value: T) => void) | undefined) {
  useLayoutEffect(() => {
    const obs2 = obs instanceof Promise ? from(obs) : obs;
    const sub = obs2.subscribe(observerOrNext);
    return () => sub?.unsubscribe();
  }, [obs, observerOrNext]);
}

/**
 * (`useLayoutEffect`) Subscribes the observer to the observable, unsubscribing when either of them change. 
 * 
 * @param obsIn The observable to subscribe. Subscribes to a Promise using `from(obs)`.
 * @param observerOrNext An observer object or a callback function.
 */
export function useForward<T>(obsIn: Observable<T> | Promise<T>, obsOut: EventEmitter<T>) {
  useLayoutEffect(() => {
    const sub = (obsIn instanceof Promise ? from(obsIn) : obsIn).subscribe((e) => { obsOut.emit(e); });
    return () => sub?.unsubscribe();
  }, [obsIn, obsOut]);
}


function subscribeEffectBody<T, U>(obs: Observable<T>, ref: { current: U }, map?: (value: T) => U) {
  return (next: () => void) => {
    const sub = obs.subscribe((e) => { ref.current = map ? map(e) : e as any; next(); });
    return () => sub.unsubscribe();
  };
}
/**
 * A useEffect wrapper which unsubscribes a cleanup subscription on cleanup.
 * 
 * @param subscribeEffect Imperative function called by useEffect that should return a cleanup subscription `{ unsubscribe: () => void }` or `undefined`.
 * @param deps Passed to useEffect with an empty array as default.
 */
export function useSubscribe<T>(subscribeEffect: () => { unsubscribe: () => void } | undefined, deps: React.DependencyList = []) {
  useEffect(() => {
    const sub = subscribeEffect();
    return () => sub?.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
/**
 * A useLayoutEffect wrapper which unsubscribes a cleanup subscription on cleanup.
 * 
 * @param subscribeEffect Imperative function called by useLayoutEffect that must return a cleanup subscription `{ unsubscribe: () => void }`.
 * @param deps Passed to useLayoutEffect with an empty array as default.
 */
export function useLayoutSubscribe<T>(subscribeEffect: () => { unsubscribe: () => void }, deps: React.DependencyList = []) {
  useLayoutEffect(() => {
    const sub = subscribeEffect();
    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
export function useLogDeps(deps: readonly any[]) {
  const lastdeps = useRef(deps);
  const changes = deps.filter((e, i) => !Object.is(e, lastdeps.current[i]));
  const res = ["deps", changes.length, changes, lastdeps.current, deps];
  lastdeps.current = deps;
  return res;
}
export function useWatchMemo(a: any) {
  const watch = useRef(a);
  const [, setFlag] = useState({});
  if (!Object.is(a, watch.current)) {
    console.log("watch", a, watch.current);
    watch.current = a;
    setFlag({});
  }
}

// export function useToggle() {
//   const [curToggle, setToggle] = useState(false);
//   const toggle = useCallback(() => setToggle((cur) => !cur), []);
//   return [curToggle, toggle];
// }

export function ButtonAwait({ onClick, loading, ...rest }: ButtonProps & { onClick?: () => Promise<void>; loading?: boolean; }) {
  const [curLoading, setLoading] = useState(false);
  const onClick2 = useCallback(async () => {

    setLoading(true);
    try { await onClick?.(); }
    finally { setLoading(false); }
  }, [onClick]);
  return <Button onClick={onClick2} loading={loading || curLoading} {...rest} />;
}
export function CheckboxAwait({ checked, label, loadingLabel, onClick }: {
  checked: boolean;
  label: string;
  loadingLabel: string;
  onClick: (newValue: boolean) => Promise<void>;
}) {

  const [loading, setLoading] = useState(false);

  return loading
    ? <span><Spinner size='small' />{loadingLabel}</span>
    : <Checkbox
      label={label}
      checked={checked}

      onChange={async (newValue) => {
        setLoading(true);
        try { await onClick(newValue); }
        finally { setLoading(false); }
      }}
    />;
}

/** Calls func.bind with the provided args, and stores the result in useMemo, refreshing whenever the func or args change */
export function useBind<T, A extends any[], B extends any[], R>(
  func: (this: T, ...args: [...A, ...B]) => R, thisArg: T, ...argArray: A
): (...args: B) => R {
  if (thisArg === undefined) throw new Error("thisArg cannot be undefined, use null if necessary");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => func.bind(thisArg, ...argArray), [func, ...argArray]);
}


export function useSubscriptionClosed(subs: Subscription) {
  return useSyncExternalStore(
    useCallback((onStoreChange) => {
      subs.add(onStoreChange);
      return () => subs.remove(onStoreChange);
    }, [subs]),
    () => subs.closed
  );
}

export function MatIcon({ children }: { children: string }) {
  return <i className="material-icons-outlined font-size-inherit">{children}</i>
}

/** Used on a div with two divs in it. 
 * 
  ```
  (
    <div style={styles.wrapper}>
      <div style={styles.cards}>
        {cards}
      </div>
      <div style={styles.tables}>
        {tables}
      </div>
    </div>
  )
    ```
 * 
 */

export const PageSidebarStyles = (twoColumns: boolean): Record<"wrapper" | "cards" | "tables", React.CSSProperties> => ({
  wrapper: twoColumns
    ? { display: "grid", gap: "1rem", gridTemplateColumns: "75% auto" } as const
    : { display: "flex", flexDirection: "column", gap: "1rem", gridTemplateColumns: "50% 50%" } as const,
  cards: twoColumns
    ? { order: 2, gap: "1rem", display: "flex", flexDirection: "column" } as const
    : { display: "grid", gridTemplateColumns: "auto", gap: "1rem" } as const,
  tables: { order: 1, gap: "1rem", display: "flex", flexDirection: "column" } as const,
} as const);

/** Throws on subsequent render if the value of a changes according to Object.is */
export function useImmutable(a: any) {
  const ref = useRef(a);
  if (!Object.is(a, ref.current)) {
    throw new Error("useImmutable changed");
  }
}


export class PromiseSubject<T> extends Promise<T> {
  #resolve: (value: T | PromiseLike<T>) => void;
  #reject: (reason?: any) => void;
  #done: boolean = false;
  public get done(): boolean { return this.#done; }
  constructor(executor?: PromiseSubject<T>);
  constructor(executor?: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void);
  constructor(executor?: ((resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void) | PromiseSubject<T>) {
    let _resolve: any, _reject: any;
    super((resolve, reject) => {
      _resolve = resolve;
      _reject = reject;
      if (typeof executor === "function") executor(resolve, reject);
    });
    this.resolve = _resolve;
    this.reject = _reject;
    if (executor instanceof Promise) executor.then(e => this.resolve(e));
  }
  resolve(a: T) {
    if (this.#done) return;
    this.#done = true;
    this.#resolve(a);
  }
  reject(a: any) {
    if (this.#done) return;
    this.#done = true;
    this.#reject(a);
  }
}


